<template>
  <li v-show="!isDeleted" class="rounded border p-4 bg-white flex items-start justify-between">
    <!-- Department name input field (when editing) / paragraph (when showing) -->
    <div class="w-full">
      <form v-if="isEditing" @submit.prevent="submitUpdateForm">
        <div class="space-y-2">
          <input
            v-model="updateForm.name"
            type="text"
            class="block w-full rounded border bg-white h-8 p-2 text-sm placeholder-opacity-50 transition-colors duration-300 ease-in-out hover:border-gray-300 disabled:border-transparent disabled:bg-gray-200 focus:outline-none focus:ring-blue-300 focus:border-blue-300"
          />
          <ErrorMessage v-if="updateForm.errors.name">
            {{ updateForm.errors.name }}
          </ErrorMessage>
        </div>
      </form>
      <p v-else class="w-full">{{ department.name }}</p>
    </div>

    <!-- Enter Edit mode & Delete buttons -->
    <div v-show="!isEditing" class="flex items-start justify-end space-x-2 w-24 flex-none">
      <!-- Enter editing mode button -->
      <SecondaryButton square @click="toggleEdit">
        <EditIcon class="w-4 h-4" />
        <span class="sr-only">{{ $t("forms.edit") }}</span>
      </SecondaryButton>

      <!-- Delete department button -->
      <SecondaryButton
        :id="'deleteDepartmentBtn' + department.id"
        square
        :busy="loadingDelete"
        @click="deleteDepartment"
      >
        <DeleteIcon class="w-4 h-4" />
        <span class="sr-only">{{ $t("forms.delete") }}</span>
      </SecondaryButton>
    </div>

    <!-- Update & Cancel editing buttons -->
    <div v-show="isEditing" class="flex items-start justify-end space-x-2 w-24 flex-none">
      <!-- Update department button -->
      <SecondaryButton square :busy="loadingEdit" @click="submitUpdateForm">
        <CheckIcon class="w-4 h-4" />
        <span class="sr-only">{{ $t("forms.save") }}</span>
      </SecondaryButton>

      <SecondaryButton square @click="toggleEdit">
        <XIcon class="w-4 h-4" />
        <span class="sr-only">{{ $t("forms.edit") }}</span>
      </SecondaryButton>
    </div>

    <ModalConfirmation
      :show="showDeleteConfirmationModal"
      :title="$t('modals.delete_department_modal_title')"
      @close="showDeleteConfirmationModal = false"
      @confirm="deleteDepartment"
    >
      {{ $t("general.confirm_deletion", { item: this.department.name }) }}
    </ModalConfirmation>
  </li>
</template>

<script>
import { useI18n } from "vue-i18n"
import httpClient from "@/helpers/http"
import { successToast } from "@/helpers/toasts"
import XIcon from "@/components/icons/XMarkIcon.vue"
import { captureError } from "@/helpers/error-helpers"
import EditIcon from "@/components/icons/EditIcon.vue"
import CheckIcon from "@/components/icons/CheckIcon.vue"
import DeleteIcon from "@/components/icons/TrashSolidIcon.vue"
import ErrorMessage from "@/components/forms/_base/ErrorMessage.vue"
import SecondaryButton from "../elements/buttons/SecondaryButton.vue"
import ModalConfirmation from "@/components/elements/modals/ModalConfirmation.vue"

export default {
  components: {
    XIcon,
    EditIcon,
    CheckIcon,
    DeleteIcon,
    ErrorMessage,
    SecondaryButton,
    ModalConfirmation,
  },
  props: {
    department: Object,
  },
  emits: ["deleteError"],
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data() {
    return {
      isEditing: false,
      loadingEdit: false,
      isDeleted: false,
      loadingDelete: false,
      updateForm: this.$inertia.form({
        _method: "PATCH",
        name: this.department.name,
      }),
      showDeleteConfirmationModal: false,
    }
  },
  methods: {
    submitUpdateForm() {
      this.updateForm.post(this.zRoute("department.update", this.department.id), {
        onStart: () => (this.loadingEdit = true),
        onSuccess: () => (this.isEditing = false),
        onFinish: () => (this.loadingEdit = false),
      })
    },

    async deleteDepartment() {
      if (!this.showDeleteConfirmationModal) {
        this.showDeleteConfirmationModal = true
        return
      }

      this.loadingDelete = true

      try {
        const response = await httpClient.delete(
          this.zRoute("department.delete", this.department.id),
        )

        if (response.data.success) {
          this.isDeleted = true
          successToast({ message: response.data.message })
        } else {
          this.$emit("deleteError", response.data)
        }
      } catch (error) {
        captureError(error)
      } finally {
        this.loadingDelete = false
        this.showDeleteConfirmationModal = false
      }
    },
    toggleEdit() {
      this.isEditing = !this.isEditing
      this.updateForm.clearErrors().reset()
    },
  },
}
</script>
